<template>
    <div>
        <div class="mb-2">
            <label class="section-label">Did anyone witness the damage?</label>
            <div :class="{ 'error--text': !liabilityWitnessId }">{{liabilityWitnessText || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="liabilityWitnessed">
            <label class="section-label">If yes, please give details of their statement</label>
            <div>{{liabilityDetails || 'No details'}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        liabilityWitnessId: Number,
        liabilityWitnessText: String,
        liabilityDetails: String,
    },
    
    computed: {
        liabilityWitnessed: function () {
            return this.liabilityWitnessId === 313670;    // Yes
        },
    },
};
</script>
