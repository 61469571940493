<template>
    <div>

        <v-row class="mt-2 mx-0">
            <v-alert type="info" dense>
                If the fault address is different to the incident address, please fill this in below.
            </v-alert>
        </v-row>

        <div class="mb-2">
            <label class="section-label">Fault location address / details</label>
            <div>{{incidentFaultLocation || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Did you attend site?</label>
            <div>{{toYesNo(incidentDidYouAttendSite)}}</div>
        </div>

        <div class="mb-2" v-if="incidentDidYouAttendSite">
            <label class="section-label">Site details</label>
            <div>{{incidentSiteDetails || 'No details'}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        incidentFaultLocation: String,
        incidentDidYouAttendSite: Boolean,
        incidentSiteDetails: String,
    },

    methods: {
        toYesNo: function(b) {
            return b ? "Yes" : "No";
        },
    },
};
</script>
