<template>
    <div>
        <div class="mb-2">
            <label class="section-label">Weather conditions at time of incident</label>
            <div :class="{ 'error--text': !weatherConditionsId }">{{weatherConditionsText || 'Not specified'}}</div>
        </div>
        <div class="mb-2">
            <label class="section-label">Road conditions at time of incident</label>
            <div :class="{ 'error--text': !roadConditionsId }">{{roadConditionsText || 'Not specified'}}</div>
        </div>
        <div class="mb-2">
            <label class="section-label">Did the incident occur in the dark?</label>
            <div :class="{ 'error--text': !wasInDarkId }">{{wasInDarkText || 'Not specified'}}</div>
        </div>
        <div class="mb-2" v-if="occurredInDark">
            <label class="section-label">If Yes, was there street lighting in the area?</label>
            <div>{{wasStreetLightingText || 'Not specified'}}</div>
        </div>
        <div class="mb-2" v-if="occurredInDark && wasStreetLighting">
            <label class="section-label">If Yes, how far is the nearest street light?</label>
            <div>{{distanceToNearestStreetLight == null ? 'Not specified' : (distanceToNearestStreetLight + 'm')}}</div>
        </div>
        <div class="mb-2">
            <label class="section-label">Vehicle Registration Number</label>
            <div>{{regNumber || 'Not specified'}}</div>
        </div>
        <div class="mb-2">
            <label class="section-label">Is the driver the owner of the vehicle?</label>
            <div>{{isDriverOwnerText || 'Not specified'}}</div>
        </div>
        <div class="mb-2">
            <label class="section-label">Driver details</label>
            <div>{{driverDetails || 'Not specified'}}</div>
        </div>
        <div class="mb-2">
            <label class="section-label">Police Report Reference Number</label>
            <div>{{policeReportRefNumber || 'Not specified'}}</div>
        </div>
        <div class="mb-2">
            <label class="section-label">Vehicle Tow Information</label>
            <div>{{vehicleTowInfo || 'Not specified'}}</div>
        </div>
        <div class="mb-2">
            <label class="section-label">Additional info relating to the RTI</label>
            <div>{{additionalInfo || 'Not specified'}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        weatherConditionsId: Number,
        weatherConditionsText: String,
        roadConditionsId: Number,
        roadConditionsText: String,
        wasInDarkId: Number,
        wasInDarkText: String,
        wasStreetLightingId: Number,
        wasStreetLightingText: String,
        distanceToNearestStreetLight: Number,
        regNumber: String,
        isDriverOwnerId: Number,
        isDriverOwnerText: String,
        driverDetails: String,
        policeReportRefNumber: String,
        vehicleTowInfo: String,
        additionalInfo: String,
    },
    
    computed: {
        occurredInDark: function () {
            return this.wasInDarkId === 313755;    // Yes
        },

        wasStreetLighting: function () {
            return this.wasStreetLightingId === 313755;    // Yes
        },
    },
};
</script>
