<template>
    <div>
        <div class="mb-2" v-if="isErs">
            <label class="section-label">Is the ERS in the business premise?</label>
            <div :class="{ 'error--text': !businessPremiseId }">{{businessPremiseText || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="isErs">
            <label class="section-label">Is the ERS drop cable related?</label>
            <div :class="{ 'error--text': !dropCableId }">{{dropCableText || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="isErs">
            <label class="section-label">Cable type</label>
            <div :class="{ 'error--text': !cableTypeId }">{{cableTypeText || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="isErs">
            <label class="section-label">Cable type detail</label>
            <div>{{cableTypeDetail || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="isErs">
            <label class="section-label">ERS - overhead or underground?</label>
            <div :class="{ 'error--text': !overheadUndergroundId }">{{overheadUndergroundText || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Incident cause</label>
            <div :class="{ 'error--text': !causeId }">{{causeText || 'No cause'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Incident type</label>
            <div :class="{ 'error--text': !typeId }">{{typeText || 'No type'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Incident description</label>
            <div
                :class="{ 'error--text': !description || description === '<p></p>' }"
                v-html="description || 'No description'"
            ></div>
        </div>

        <div class="mb-2">
            <label class="section-label">Damaged apparatus location</label>
            <div :class="{ 'error--text': !subLocationId }">{{subLocationText || 'No location'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Does this relate to PIA work?</label>
            <div :class="{ 'error--text': !relatesToPiaId }">{{relatesToPiaText || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="isPia">
            <label class="section-label">Grid reference</label>
            <div :class="{ 'error--text': !piaGridReference }">{{piaGridReference || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="isPia">
            <label class="section-label">PIA whereabouts ID</label>
            <div :class="{ 'error--text': !piaWhereaboutsId }">{{piaWhereaboutsId || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="isPia">
            <label class="section-label">PIA NOI Ref</label>
            <div :class="{ 'error--text': !piaExchangeCode }">{{piaExchangeCode || 'Not specified'}}</div>
        </div>
        
        <div class="mb-2" v-if="isPia">
            <label class="section-label">PIA - overhead or underground?</label>
            <div :class="{ 'error--text': !piaOverheadUndergroundId }">{{piaOverheadUndergroundText || 'Not specified'}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        typeSystemName: String,

        causeId: Number,
        causeText: String,
        typeId: Number,
        typeText: String,
        description: String,
        subLocationId: Number,
        subLocationText: String,
        relatesToPiaId: Number,
        relatesToPiaText: String,
        piaGridReference: String,
        piaWhereaboutsId: String,
        piaExchangeCode: String,
        piaOverheadUndergroundId: Number,
        piaOverheadUndergroundText: String,


        // ERS
        businessPremiseId: Number,
        businessPremiseText: String,
        dropCableId: Number,
        dropCableText: String,
        cableTypeId: Number,
        cableTypeText: String,
        cableTypeDetail: String,
        overheadUndergroundId: Number,
        overheadUndergroundText: String,
    },
    
    computed: {
        isErs: function () {
            return (this.typeSystemName || "").endsWith("ers");
        },

        isPia: function () {
            return this.relatesToPiaId === 500000181;  // Yes
        },
    },
};
</script>
