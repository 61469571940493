<template>
    <div>
        <div class="mb-2">
            <label class="section-label">Please confirm network type</label>
            <div :class="{ 'error--text': !networkTypeIds.length }">{{networkTypeTexts.join(", ") || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Please confirm if network is VMO2 or Nexfibre</label>
            <div :class="{ 'error--text': !vmo2NexfibreId }">{{vmo2NexfibreText || 'Not specified'}}</div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        networkTypeIds: Array,
        networkTypeTexts: Array,
        vmo2NexfibreId: Number,
        vmo2NexfibreText: String,
    },
};
</script>
