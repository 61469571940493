<template>
    <div>
        <div class="mb-2">
            <label class="section-label">Repairs completed date</label>
            <div>{{repairsCompletedDate || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Was this a permanent or temporary repair?</label>
            <div :class="{ 'error--text': !repairTypeId }">{{repairTypeText || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Repair details</label>
            <div :class="{ 'error--text': !repairDetails }">{{repairDetails || 'No details'}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        repairsCompletedDate: String,   // "DD/MM/YYYY"
        repairTypeId: Number,
        repairTypeText: String,
        repairDetails: String,
    },
};
</script>
